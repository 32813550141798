<template>
  <div class="page-layout__inner">
    <div class="page-layout__content">
      <div v-if="hasPermission('application.stats.clientStats') || hasPermission('application.stats.companyStats')" class="row">

        <div v-if="hasPermission('application.stats.clientStats')" class="col-6">
          <div class="card">
            <div class="card-body p-3 d-flex align-items-center">
              <div class="bg-primary p-3 mfe-3">
                <CIcon name="cil-people"/>
              </div>
              <div>
                <div class="text-value-lg  text-primary">{{ clientStats }}</div>
                <router-link :to="{ name:'client.getList' }"><span class="text-muted text-uppercase font-weight-bold small">{{ getTranslation('clients') }}</span></router-link>
              </div>
            </div>
          </div>
        </div>

        <div v-if="hasPermission('application.stats.companyStats')" class="col-6">
          <div class="card">
            <div class="card-body p-3 d-flex align-items-center">
              <div class="bg-info p-3 mfe-3">
                <CIcon name="cil-airplane-mode"/>
              </div>
              <div>
                <div><span class="text-value-lg text-info">{{ companyStats.count_total }}</span> <router-link :to="{name:'company.getList',query:{status:'NEW'}}" ><span class="text-success"> +{{ companyStats.count_new }}</span></router-link> </div>
                <router-link :to="{name:'company.getList'}">  <span class="text-muted text-uppercase font-weight-bold small">{{ getTranslation('companies') }}</span> </router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-if="hasPermission('application.stats.mainStats')" class="row">
        <div class="col-sm-6 col-lg-3">
          <div class="card">
            <div class="card-body">
              <div class="text-value-lg">{{ mainStats.count_new }}</div>
              <router-link :to="{name:'application.getList',query:{status:'NEW'}}"><small class="text-muted text-uppercase font-weight-bold">{{ getTranslation('New applications') }}</small></router-link>
              <div class="progress progress-xs mt-3 mb-0">
                <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card">
            <div class="card-body">
              <div class="text-value-lg">{{ mainStats.count_confirmed }}</div>
              <router-link :to="{name:'application.getList',query:{status:'CONFIRMED'}}"><small class="text-muted text-uppercase font-weight-bold">{{ getTranslation('Confirmed applications') }}</small></router-link>
              <div class="progress progress-xs mt-3 mb-0">
                <div class="progress-bar bg-primary" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card">
            <div class="card-body">
              <div class="text-value-lg">{{ mainStats.count_waiting }}</div>
              <router-link :to="{name:'application.getList',query:{status:'WAITING_PROVIDER'}}"><small class="text-muted text-uppercase font-weight-bold">{{ getTranslation('Waiting for agents applications') }}</small>
              </router-link>
              <div class="progress progress-xs mt-3 mb-0">
                <div class="progress-bar bg-warning" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="card">
            <div class="card-body">
              <div class="text-value-lg">{{ mainStats.count_rejected }}</div>
              <router-link :to="{name:'application.getList',query:{status:'REJECTED'}}"><small class="text-muted text-uppercase font-weight-bold">{{ getTranslation('Rejected applications') }}</small></router-link>
              <div class="progress progress-xs mt-3 mb-0">
                <div class="progress-bar bg-danger" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      mainStats: {
        count_new: 0,
        count_confirmed: 0,
        count_waiting: 0,
        count_rejected: 0
      },
      clientStats: 0,
      companyStats: {
        count_total: 0,
        count_new: 0,
        count_confirmed: 0,
        count_rejected: 0
      },
    }
  },
  created() {
    this.setHeadings({
      title: 'Dashboard',
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: { name: 'admin.home' }
        }
      ]
    });

    if ( this.hasPermission('application.stats.mainStats') )
      this.getMainStats();
    if ( this.hasPermission('application.stats.clientStats') )
      this.getClientStats();
    if ( this.hasPermission('application.stats.companyStats') )
      this.getCompanyStats();
  },
  methods: {
    getMainStats() {
      this.axios.get(this.$urls.home.mainStats).then(resp => {
        this.mainStats = resp.data.data;
      }).catch(() => {
      });
    },
    getClientStats() {
      this.axios.get(this.$urls.home.clientStats).then(resp => {
        this.clientStats = resp.data.data;
      }).catch(() => {
      });
    },
    getCompanyStats() {
      this.axios.get(this.$urls.home.companyStats).then(resp => {
        this.companyStats = resp.data.data;
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>

</style>